import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import Icon from '@/shared/components/Icon';
import { SxProps } from '@mui/system';

export default function NoData(props: { sx?: SxProps }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '17px',
        ...props.sx,
      }}
    >
      <Icon
        width={{ xs: 32 }}
        height={{ xs: 35 }}
        color={colors.steel}
        icon="search-nodata"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography
          component="div"
          variant="h3"
          sx={{
            color: colors.black,
          }}
        >
          Sorry, but no entities for this query
        </Typography>
        <Typography
          component="div"
          variant="h5"
          sx={{
            color: colors.black,
            fontWeight: 400,
          }}
        >
          We recommend rephrasing your search query or using a different query
        </Typography>
      </Box>
    </Box>
  );
}
