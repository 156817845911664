import { colors } from '@/lib/theme/palette';
import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import Icon, { IconProps } from '../Icon';
import { SearchData } from './types';
import SearchLoader from './SearchLoader';
import NoData from './NoData';
import { getHighlightedText } from '@/lib/utils';
import Image from 'next/image';

type Props = {
  searchValue: string;
  searchData: SearchData;
  loading: boolean;
  onHideMenu?: () => void;
};

const HeaderBlock = (props: {
  title: string;
  link: string;
  icon: IconProps['icon'];
  searchQuery: string;
  fullLink?: string;
  onHideMenu?: () => void;
  sx?: SxProps;
}) => {
  return (
    <Box display={'flex'} alignItems={'center'} sx={{ ...props.sx }}>
      <Typography
        display={'flex'}
        alignItems={'center'}
        fontSize={14}
        fontWeight={700}
        color={colors.black500}
        mr={'auto'}
      >
        <Icon
          icon={props.icon}
          height={16}
          width={16}
          color={colors.black500}
          sx={{
            mr: 1,
          }}
        />
        {props.title}
      </Typography>

      <Link
        href={
          props.fullLink ||
          `/library?type=${props.link}&query=${props.searchQuery}`
        }
        onClick={props.onHideMenu}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.blue}>
          See all results
        </Typography>
      </Link>
    </Box>
  );
};

function MobileResults(props: Props) {
  if (props.loading) {
    return (
      <SearchLoader
        sx={{
          position: 'fixed',
          top: '76px',
          width: '100%',
          left: 0,
          background: colors.white,
          padding: '20px 24px',
        }}
      />
    );
  }

  return !props.searchData.categories.length &&
    !props.searchData.companies.length &&
    !props.searchData.pdf_resources.length ? (
    <NoData
      sx={{
        position: 'fixed',
        top: '76px',
        width: '100%',
        left: 0,
        background: colors.white,
        padding: '20px 24px',
      }}
    />
  ) : (
    <Box
      padding={'20px 24px'}
      sx={{
        position: 'fixed',
        left: 0,
        top: 76,
        width: '100%',
        backgroundColor: colors.white,
        zindex: 2,
        borderTop: '1px solid #F1F3FE',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <Box>
        {props.searchData.categories.length ? (
          <>
            <Box mb={2}>
              <HeaderBlock
                icon="categories"
                link="categories"
                title="Technologies"
                fullLink="/technologies"
                searchQuery={props.searchValue}
                onHideMenu={props.onHideMenu}
                sx={{
                  mb: 3,
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                {props.searchData.categories.map(category => (
                  <Link
                    href={`/library?categories=${category.value}`}
                    key={`category${category.id}`}
                    onClick={props.onHideMenu}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 400,
                        cursor: 'pointer',
                        '& span': {
                          fontWeight: 700,
                          color: colors.blue,
                        },
                      }}
                    >
                      {getHighlightedText(category.name, props.searchValue)}
                    </Typography>
                  </Link>
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <> </>
        )}

        {props.searchData.companies.length ? (
          <>
            <Divider sx={{ mb: 2 }} />
            <Box mb={2}>
              <HeaderBlock
                icon="building"
                link="companies"
                title="Companies"
                searchQuery={props.searchValue}
                onHideMenu={props.onHideMenu}
                sx={{
                  mb: 3,
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {props.searchData.companies.map(company => {
                  return (
                    <Link
                      href={`/company/${company.id}`}
                      key={`company${company.id}`}
                      onClick={props.onHideMenu}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Image
                          src={company.image_path || '/company-default-img.png'}
                          loading="lazy"
                          alt={`Company ${company.name} icon`}
                          width={32}
                          height={32}
                          style={{ objectFit: 'contain' }}
                        />
                        <Box
                          sx={{
                            padding: '0 0 0 20px',
                          }}
                        >
                          <Typography
                            component="div"
                            variant="h4"
                            sx={{
                              padding: '0 0 3px 0',
                              fontWeight: 700,
                              cursor: 'pointer',

                              '& span': {
                                fontWeight: 700,
                                color: colors.blue,
                              },
                            }}
                          >
                            {getHighlightedText(
                              company.name,
                              props.searchValue,
                            )}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              flexWrap: 'wrap',
                              '& .category-divider': {
                                '&:last-child': {
                                  display: 'none',
                                },
                              },
                            }}
                          >
                            {company.categories.map(category => (
                              <>
                                <Typography
                                  key={`category_${category.value}`}
                                  component="span"
                                  variant="subtitle2"
                                  sx={{
                                    fontWeight: 400,
                                    color: colors.black,
                                  }}
                                >
                                  {category.name || 'No category'}
                                </Typography>
                                <Typography
                                  component="span"
                                  className="category-divider"
                                  variant="body2"
                                  sx={{
                                    color: colors.black,
                                  }}
                                >
                                  ·
                                </Typography>
                              </>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  );
                })}
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}

        {props.searchData.pdf_resources.length ? (
          <>
            <Divider sx={{ mb: 2 }} />
            <Box>
              <HeaderBlock
                icon="resources"
                link="resources"
                title="Resources"
                searchQuery={props.searchValue}
                onHideMenu={props.onHideMenu}
                sx={{
                  mb: 3,
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '22px',
                }}
              >
                {props.searchData.pdf_resources.map(resource => (
                  <Link
                    key={`resource${resource.id}`}
                    href={`/resource/${resource.id}`}
                    onClick={props.onHideMenu}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '7px',
                      }}
                    >
                      {' '}
                      <Typography
                        variant="h4"
                        sx={{
                          cursor: 'pointer',

                          '& span': {
                            fontWeight: 700,
                            color: colors.blue,
                          },
                        }}
                      >
                        {getHighlightedText(resource.name, props.searchValue)}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        {/* <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: colors.black700,

                            '& span': {
                              fontWeight: 700,
                              color: colors.blue,
                            },
                          }}
                        >
                          {getHighlightedText(
                            resource.company.name || 'No company',
                            props.searchValue,
                          )}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: colors.black700,
                          }}
                        >
                          ·
                        </Typography> */}
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: colors.black700,
                          }}
                        >
                          {resource.resource_type || 'No type'}
                        </Typography>
                      </Stack>
                    </Box>
                  </Link>
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

export default MobileResults;
