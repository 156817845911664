import * as React from 'react';
import { SearchData } from '@/shared/components/SearchBar/types';
import Categories from '@/shared/components/SearchBar/Categories';
import Companies from '@/shared/components/SearchBar/Companies';
import Resources from '@/shared/components/SearchBar/Resources';
import NoData from '@/shared/components/SearchBar/NoData';
import SearchLoader from './SearchLoader';

type Props = {
  searchData: SearchData;
  searchQuery: string;
  loading: boolean;
  onHideMenu?: () => void;
};

export default function SearchResultsPreview({
  searchData,
  searchQuery,
  loading,
  onHideMenu,
}: Props) {
  if (loading) {
    return <SearchLoader />;
  }
  return !searchData.categories.length &&
    !searchData.companies.length &&
    !searchData.pdf_resources.length ? (
    <NoData />
  ) : (
    <>
      {searchData.categories.length ? (
        <Categories
          searchQuery={searchQuery}
          categories={searchData.categories}
          onHideMenu={onHideMenu}
        />
      ) : (
        ''
      )}
      {searchData.companies.length ? (
        <Companies
          companies={searchData.companies}
          onHideMenu={onHideMenu}
          searchQuery={searchQuery}
        />
      ) : (
        ''
      )}
      {searchData.pdf_resources.length ? (
        <Resources
          searchQuery={searchQuery}
          resources={searchData.pdf_resources}
          onHideMenu={onHideMenu}
        />
      ) : (
        ''
      )}
    </>
  );
}
